.future-fee-config {
  width: 900px;
  margin: 0 auto;
  border: none;
  padding: 20px;
}

.country-codes-div {
  display: flex;
  flex-wrap: wrap;
}

.country-code-checkbox {
  flex-basis: 10%;

}
.save-button{
  margin-top: 20px;
}

.dates-container{
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
}