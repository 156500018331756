@import './src/styles/variables';

#app-header {
  color: #d6d6d6;
  background-color: #002f36;
  display: grid;
  padding: 15px 20px;
  grid-template-columns: 180px auto auto;
  grid-template-areas:
    'logo toggle resources'
    'nav nav nav';

  .app-header-logo {
    grid-area: logo;
    display: flex;
    align-items: center;
  }

  .header-title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    color: #ffffff
  }

  .app-header-toggle {
    grid-area: toggle;
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  .nav-link {
    color: #d6d6d6;
    display: block;
    padding: 0.5rem;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;

    &:focus,
    &:hover {
      color: #fff;
      background: #18484f;
      text-decoration: none;
    }

    &.active {
      background: #1c535c;
      color: #fff;
    }
  }
}
