.banner-conf-container{
  padding: 20px;
}

.tab-context-div {
  padding: 20px;
}

.tab-alarm-banner {
  max-width: 600px;
}

.style-preview-banner {
  max-width: 600px;
  margin-top: 5px;
}

.dropdown-with-slot-option {
  display: flex;
  align-items: center;
}

.badge-item {
  flex: 1;
  text-align: right;
}

.selection-group{
  display: flex;
  flex: 1;
  align-items: end;
  margin-bottom: 10px;
  .selection-button {
    margin-right: 10px;
  }
  .selection-dropdown{
    width: 220px;
    margin-right: 10px;
  }
  .revision-dropdown{
    width: 300px;
    margin-right: 10px;
  }
}

.profile-button-div {
  display: flex;
}

.country-codes-div {
  margin: 10px;
  display: flex;
  max-width: 600px;
  flex-wrap: wrap;

  .country-code-checkbox {
    margin-right: 20px;
  }
}

.profile-button {
  margin: 10px;
}

.basic-width {
  width: 600px
}

.banner-style-div {
  display: flex;
  align-items: flex-end;
}

.priority-input {
  width: 100px;
}

.variant-dropdown {
  width: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.string-context-area {
  margin-top: 10px;
  width: 600px;
  --resize: none
}

.sub-title-div {
  margin-top: 20px;
}

.sub-title-desc {
  font-size: 10px;
  font-weight: bold;
}

.multi-kv-input-group {
  width: 600px;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  .middle-label {
    margin-top: 5px;
  }

  .key-input-box {
    width: 200px;
    margin-right: 5px;
  }

  .value-input-box {
    width: 300px;
    margin-left: 5px;
  }

  .multi-kv-del-button {
    margin-left: 10px;
  }
}

.multi-kv-add-button {
  margin-top: 10px;
}

.input-box {
  width: 400px;
  margin-top: 10px;
}